.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: none;
}

.menu-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.menu-button:hover {
  background-color: #f0f0f0;
  color: #000;
}

.menu-button:active {
  background-color: #ddd;
  transform: scale(0.95);
}
