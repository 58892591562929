@import "./styles/GlobalStyles.css";

body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 16px;
}

.container {
  max-width: 400px;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 24px;
}

.profile-info {
  text-align: center;
  margin-bottom: 16px;
}

.profile-picture {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 8px;
}

.profile-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.profile-description {
  font-size: 14px;
  color: #555;
}

.profile-buttons {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.button {
  flex: 1;
  margin: 0 4px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.button-add {
  background-color: #f5f5f5;
  color: #333;
}

.button-share {
  background-color: #ff585d;
  color: #fff;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.collection-card {
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 16px 0;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.collection-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.collection-text {
  margin-left: 12px;
}

.collection-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.collection-subtitle {
  font-size: 14px;
  color: #777;
}