@import "./styles/GlobalStyles.css";

.App {
  text-align: center;
}

.button-group {
  display: flex;
  gap: 10px;
}

.button {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 16px; 
  border-radius: 20px;
  cursor: pointer;
  margin: 5px;
}

.button.add {
  background-color: #ff4b4b;
  color: #fff; 
  border: none; 
  padding: 10px 50px; 
  border-radius: 18px; 
  font-size: 14px; 
  font-weight: bold; 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.button.add:hover {
  background-color: #e04343; 
  transform: translateY(-2px);
}

.button.share {
  background-color: #f5f5f5;
  color: #000; 
  border: 1px solid #ccc; 
  padding: 10px 50px; 
  border-radius: 18px; 
  font-size: 14px; 
  font-weight: bold; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.button.share:hover {
  background-color: #eaeaea; 
  transform: translateY(-2px);
}


