.title {
    font-size: 48px;
    line-height: 1.2;
    font-family: Inter,Helvetica,Arial,system-ui,-apple-system;
}

.extra_bold {
    font-weight: 800;
}

.margin{
    margin: 20px;
}

.margin_left{
    margin-left: 20px;
}

.margin_right{
    margin-right: 20px;
}

.margin_bottom{
    margin-bottom: 20px;
}

.margin_top{
    margin-top: 20px;
}

.mb_xs{
    margin-bottom: 0.5rem;
}

.ml_xs{
    margin-left: 0.1rem;
}

.padding_lr{
    padding: 30px;
}

.padding_m{
    padding: 20px;
}

.padding_sm{
    padding: 10px;
}

.center{
  text-align: center;
  margin: auto;
  justify-content: center;
}


.text_center{
    margin: auto;
    text-align: center;
}

.text_left{
    text-align: left;
}

.text_right{
    text-align: right;
}

.small_text{
    font-size: 18px;
    line-height: 1.5;
}

.clickable_link{
    text-decoration: inherit;
    display: inline-flex;
    color: rgb(129 41 217);
}

.underline {
    text-decoration-line: underline;
}

.justify_center{
    justify-content: center;
}

.justify_space{
  justify-content: space-between;
}

.inline_items{
  display: inline;
}