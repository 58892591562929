.link-card {
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    background-color: #fff;
    text-decoration: none;
    color: inherit;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    cursor: pointer;
  }

  .link-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  .link-image {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 12px;
  }
  
  .link-text {
    flex-grow: 1;
  }
  
  .link-title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 4px;
  }
  
  .link-subtitle {
    display: block;
    font-size: 16px;
    color: #777;
    margin-top: 4px;
  }
  
  .add-link {
    font-size: 14px;
    color: #0066cc;
    text-decoration: none;
    font-weight: 500;
  }
  