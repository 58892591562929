.main{
    height: 100vh;
    text-align: left;
  }

.main_image {
    width: 100%;
    height: auto;
    display: block;
}

.circular-image-container{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.aligment_left {
    text-align: left;
}

margin_left{
    margin-left: 10px;
}

.profile-name {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 5px 0;
}

.profile-description {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #555; /* Adjust for a subtle gray color */
  margin: 0 0 15px 0;
  text-align: center;
  display: inline-block;
  text-align: left;
}

@media (min-width: 750px) {
    .main {
      max-width: 70%; 
      margin: auto;
    }
  }