.navbar {   
    display:flex;
    align-items: center;
    padding: 1.5rem 2rem;
    background-color: white;
  }

.logo{
    width: 30px;
}

.nav-links{
display: contents;
}

.container {
    display: inline-grid;
  }

  .profile-title{
    color: black;
    text-align: left;
    text-align: center;
    margin: auto;
  }

  